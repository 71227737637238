import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { reducers } from '../store/configureAdminDecChargesStore';
import withReducers from '../store/withReducers';
import Header from './Header';
import MainSection from './MainSection';

import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import AdminDecorations from '../components/admin-decoration-matrix/AdminDecorations';

import { getPopups } from '../selectors';
import 'react-virtualized/styles.css';
import '../../scss/css/admin-decoration.css';

class AdminDecorationChargesApp extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { identity, popups, nav_items } = this.props;
        const isActive = (url) => window.location.href.indexOf(url) != -1;

        return (
            <ErrorBoundary>
                <Header>
                    <div className="columns decoration-matrix">
                        <ul className="tabs show-for-medium" data-tabs id="admin-nav">
                            {nav_items.map((val, idx) => {
                                const active = isActive(val.link);
                                return (
                                    <li key={val + '_' + idx } className={'tabs-title' + (active ? ' is-active' : '')} style={{background: 'white'}} data-index={idx}>
                                        <a aria-selected={active} href={'/' + val.link}>{val.title}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </Header>
                <MainSection popups={popups ? popups : []} style={{background: '#EDF2F5'}}>
                    <AdminDecorations identity={identity} style={{background: '#EDF2F5'}} {...this.props} />
                </MainSection>
                <Overlay popups={popups} />
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const nav_items = state.display.nav_items;
    return {
        identity: state.identity,
        popups: getPopups(state),
        user_id: state.identity ? state.identity.user_id : null,
        nav_items: nav_items,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default withReducers(connect(mapStateToProps, mapDispatchToProps)(AdminDecorationChargesApp), reducers, true);
