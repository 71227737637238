import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import api from '../middleware/api';
// import rootReducer from '../reducers/core'
import displayReducer from '../reducers/display';
import tempReducer from '../reducers/temp';
import { decorationReducer, decoratorReducer, decoratorIdReducer } from '../reducers/admin_decoration_charges';
import { divisionReducer, supplierReducer } from '../reducers/supplier';

import { window, getInitialState } from '../global';

var initialState = getInitialState();

const defaultDropdownState = initialState.dropdowns ? initialState.dropdowns : {};
const dropdowns = (state = defaultDropdownState, action) => {
  const new_state = Object.assign({}, state, {
    divisions: divisionReducer(state.divisions ? state.divisions : [], action),
    suppliers: supplierReducer(state.suppliers ? state.suppliers : [], action),
    decorators: decoratorReducer(state.decorators ? state.decorators : [], action),
  });

  return new_state;
};

const defaultIdentityState = initialState.identity ? initialState.identity : {};
const identityReducer = (state = defaultIdentityState, action) => {
  return state;
};

const defaultEntitiesState = initialState.entities ? initialState.entities : {};
const entitiesReducer = (state = defaultEntitiesState, action) => {
  const new_state = Object.assign({}, state, {
    decorations: decorationReducer(state.decorations ? state.decorations : [], action),
    decorator_id: decoratorIdReducer(state.decorator_id ? state.decorator_id : '', action),
  });

  return new_state;
};

export const reducers = {
  display: displayReducer,
  temp: tempReducer,
  dropdowns: dropdowns,
  entities: entitiesReducer,
  identity: identityReducer,
};

export default function configureAdminDecChargesStore(initialState) {
  const store = createStore(
    combineReducers(reducers),
    initialState,
    compose(
      applyMiddleware(thunk, api),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );

  return store;
}
